html,
body,
body>div#root {
    height: 100%;
}

#root {
    display: flex;
    flex-direction: column;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-weight: 700;
    font-size: 18px;
}

h1 {
    font-size: 2rem;
}

h2 {
    font-size: 1.75rem;
}

h3 {
    font-size: 1.5rem;
}

h4 {
    font-size: 1.25rem;
}

h5 {
    font-size: 1rem;
    margin-bottom: 5px;
}

p {
    font-size: 0.75rem;
    margin-bottom: 5px;
}